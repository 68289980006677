<template>
  <div class="pageScore generalScore">
    <h3>Page Score
      <span v-if="date">- {{ date }}</span>
    </h3>
    <div class="d-md-flex">
      <div class="w-1/2">
        <div>
          <apex
            v-if="optionsChart"
            type="radialBar"
            height="280px"
            width="280px"
            :options="optionsChart.chartOptions"
            :series="optionsChart.series"
          />
        </div>
      </div>
      <div class="info w-100 flex align-items-center md:w-50">
        <div>
          <p>Optimize and improve your <br> Page Score with us</p>
          <ol>
            <li><span class="dot bad" /> 0-39 Bad</li>
            <li><span class="dot average" /> 40-59 Average</li>
            <li><span class="dot good" /> 60-89 Good</li>
            <li><span class="dot excellent" /> 90-100 Excellent</li>
          </ol>
          <button class="button-seocloud-outline">
            Fix this
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apex from 'vue-apexcharts'

export default {
  components: {
    apex,
  },
  props: {
    updates: {
      type: Object,
      required: true,
      default: () => {},
    },
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      optionsChart: {
        series: [0],
        chartOptions: {
          chart: {
            height: 350,
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: '70%',
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  color: '#232C51',
                  fontSize: '69px',
                  show: true,
                },
                value: {
                  color: '#232C51',
                  fontSize: '36px',
                  show: true,
                  formatter(val) {
                    if (val >= 0 && val <= 39) return 'bad'
                    if (val >= 40 && val <= 59) return 'average'
                    if (val >= 60 && val <= 89) return 'good'
                    if (val >= 90) return 'excellent'
                    return ''
                  },
                },
              },
            },

          },
          fill: {
            colors: ['#FC7D7D'],
            opacity: 1,
          },
          stroke: {
            lineCap: 'round',
          },
          labels: ['wait'],
        },
      },
    }
  },
  watch: {
    updates() {
      this.optionsChart.series = [this.updates.scores]
      this.optionsChart.chartOptions.labels[0] = this.updates.labels
      this.optionsChart.chartOptions.fill.colors[0] = this.updates.colors
    },
  },
  created() {
    this.optionsChart.series = this.updates.scores
    this.optionsChart.chartOptions.labels[0] = this.updates.labels
    this.optionsChart.chartOptions.fill.colors[0] = this.updates.colors
  },
}
</script>
