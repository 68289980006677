function getColorChart(val) {
  if (val >= 0 && val <= 39) return '#FC7D7D'
  if (val >= 40 && val <= 59) return '#FFA54E'
  if (val >= 60 && val <= 89) return '#FBD365'
  if (val >= 90) return '#53D991'
  return '#53D991'
}

function getColorLoadTime(val) {
  if (val >= 0 && val <= 27.45) return '#FC7D7D'
  if (val >= 27.46 && val <= 47.39) return '#FFA54E'
  if (val >= 47.40 && val <= 100) return '#53D991'
  return '#FC7D7D'
}

function getColorChartVariant(val) {
  if (val >= 0 && val <= 39) return 'red'
  if (val >= 40 && val <= 59) return 'warning'
  if (val >= 60 && val <= 89) return 'third'
  if (val >= 90) return 'green'
  return 'green'
}

function getColorLoadTimeVariant(val) {
  if (val >= 0 && val <= 27.45) return 'red'
  if (val >= 27.46 && val <= 47.39) return 'warning'
  if (val >= 47.40 && val <= 100) return 'green'
  return 'red'
}

function generateTimes() {
  const today = new Date()
  // Last month
  const previousMonth = new Date()
  previousMonth.setMonth(today.getMonth() - 1)
  // Last year
  const previousYear = new Date()
  previousYear.setFullYear(today.getFullYear() - 1)
  // Last week
  const lastWeek = today.getTime() - (7 * 24 * 60 * 60 * 1000)
  return {
    today: today.getTime(),
    previousYear: previousYear.getTime(),
    previousMonth: previousMonth.getTime(),
    lastWeek,
  }
}

function generateDateString(date, isTime) {
  const datePost = isTime ? new Date(date * 1000) : new Date(date)
  const options = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }
  return datePost.toLocaleString('en-US', options)
}

function generateDateStringNoDay(date, isTime) {
  const datePost = isTime ? new Date((date * 1000)) : new Date(date)
  const year = datePost.getFullYear()
  const month = datePost.toLocaleString('en-US', { month: 'long' })
  return `${datePost.toLocaleString('en-US', { day: 'numeric' })}th ${month}, ${year}`
}

const generateDateTime = date => new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

function formatDataToResumeToolTipClick(dataPointIndex, history) {
  const data = {}
  data.performanceDesktop = history.performance.desktop[dataPointIndex]
  data.performanceMobile = history.performance.mobile[dataPointIndex]
  data.loadtimeDesktop = history.loadTime.desktop[dataPointIndex]
  data.loadtimeMobile = history.loadTime.mobile[dataPointIndex]
  data.performanceDesktop.color = getColorChartVariant(data.performanceDesktop.y)
  data.performanceMobile.color = getColorChartVariant(data.performanceMobile.y)
  data.loadtimeDesktop.color = getColorLoadTimeVariant(data.loadtimeDesktop.y)
  data.loadtimeMobile.color = getColorLoadTimeVariant(data.loadtimeMobile.y)
  data.date = generateDateStringNoDay(data.loadtimeMobile.x)
  data.time = generateDateTime(data.loadtimeMobile.x)
  data.dKey = data.performanceDesktop.key
  data.mKey = data.performanceMobile.key
  return data
}

function hoverIndexKeys(dataPointIndex, history) {
  const data = {}
  const performanceDesktop = history.performance.desktop[dataPointIndex]
  const performanceMobile = history.performance.mobile[dataPointIndex]
  data.dKey = performanceDesktop.key
  data.mKey = performanceMobile.key
  return data
}

function renderObject() {
  const average = {
    year: {
      performance: {
        mobile: [],
        desktop: [],
      },
      loadtime: {
        mobile: [],
        desktop: [],
      },
    },
    month: {
      performance: {
        mobile: [],
        desktop: [],
      },
      loadtime: {
        mobile: [],
        desktop: [],
      },
    },
    week: {
      performance: {
        mobile: [],
        desktop: [],
      },
      loadtime: {
        mobile: [],
        desktop: [],
      },
    },
  }
  const history = {
    desktop: [],
    mobile: [],
  }
  const chartData = {
    performance: {
      desktop: [],
      mobile: [],
    },
    loadtime: {
      desktop: [],
      mobile: [],
    },
  }
  const lastItems = {}
  return {
    average, history, chartData, lastItems,
  }
}

function formatPerformance(val) {
  const performanceData = renderObject()
  const { previousYear, previousMonth, lastWeek } = generateTimes()

  if (!val) {
    return performanceData
  }

  const filterByDate = (data, date) => Object.values(data).filter(x => x.x >= date)

  performanceData.chartData = val

  // Year Average
  performanceData.average.year.performance.mobile = filterByDate(
    val.performance.mobile,
    previousYear,
  )
  performanceData.average.year.performance.desktop = filterByDate(
    val.performance.desktop,
    previousYear,
  )
  performanceData.average.year.loadtime.mobile = filterByDate(
    val.loadTime.mobile,
    previousYear,
  )
  performanceData.average.year.loadtime.desktop = filterByDate(
    val.loadTime.desktop,
    previousYear,
  )

  // Month Average
  performanceData.average.month.performance.mobile = filterByDate(
    val.performance.mobile,
    previousMonth,
  )
  performanceData.average.month.performance.desktop = filterByDate(
    val.performance.desktop,
    previousMonth,
  )
  performanceData.average.month.loadtime.mobile = filterByDate(
    val.loadTime.mobile,
    previousMonth,
  )
  performanceData.average.month.loadtime.desktop = filterByDate(
    val.loadTime.desktop,
    previousMonth,
  )

  // Week Average
  performanceData.average.week.performance.mobile = filterByDate(
    val.performance.mobile,
    lastWeek,
  )
  performanceData.average.week.performance.desktop = filterByDate(
    val.performance.desktop,
    lastWeek,
  )
  performanceData.average.week.loadtime.mobile = filterByDate(
    val.loadTime.mobile,
    lastWeek,
  )
  performanceData.average.week.loadtime.desktop = filterByDate(
    val.loadTime.desktop,
    lastWeek,
  )

  // Registrar los ultimos datos
  performanceData.lastItems.desktop = val.lastDesktop
  performanceData.lastItems.mobile = val.lastMobile

  return performanceData
}

export default {
  getColorChart,
  formatPerformance,
  getColorLoadTime,
  generateTimes,
  generateDateString,
  generateDateStringNoDay,
  getColorChartVariant,
  getColorLoadTimeVariant,
  generateDateTime,
  formatDataToResumeToolTipClick,
  hoverIndexKeys,
}
