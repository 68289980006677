<template>
  <div class="__performance-module-seocloud">
    <h2 v-if="website">
      Site audit: <b>{{ website }}</b>
    </h2>
    <b-skeleton
      v-else
      class="h2Skeleton"
    />
    <nav class="menu_testResults">
      <ul v-if="!actualType">
        <li><b-skeleton class="skeleton-nav" /></li><li><b-skeleton class="skeleton-nav" /></li><li><b-skeleton class="skeleton-nav" /></li>
      </ul>
      <ul v-else>
        <li><a
          :class="actualType === 'history' ? 'active' : ''"
          @click="actualType = 'history'"
        >History</a>
        </li>
        <li><a
          :class="actualType === 'desktop' ? 'active' : ''"
          @click="renderValues('desktop')"
        >Desktop</a></li>
        <li><a
          :class="actualType === 'mobile' ? 'active' : ''"
          @click="renderValues('mobile')"
        >Mobile</a></li>
      </ul>
    </nav>
    <div
      v-show="actualType !== 'history'"
      class="mb-1 cursor-pointer"
      @click="actualType = 'history'"
    >
      <span><feather-icon
        icon="ArrowLeftIcon"
        size="36"
        class="inline-block text-primary"
        style="margin-top:-2px"
      /></span>
    </div>
    <transition>
      <template v-if="!performanceResults">
        <b-row>
          <b-col md="8">
            <b-skeleton-img height="550px" />
          </b-col>
          <b-col md="4">
            <b-skeleton-img height="550px" />
          </b-col></b-row>
      </template>
      <History
        v-else
        v-show="actualType == 'history'"
        :history="performanceResults.chartData"
        :group-dates="performanceResults.average"
        :site-key="siteKey"
        :cache-data="dataCache"
        @getData="updateChartByRange"
        @update="tryUpdate"
      />
    </transition>

    <section v-if="actualType !== 'history'">
      <b-row style="margin: 0 -1rem!important;">
        <b-col md="8">
          <score
            v-if="currentItem"
            :updates="chartUpdate"
            :date="date"
          />
        </b-col>
        <b-col md="4">
          <statics
            v-if="currentItem"
            :current-item="currentItem"
          />
        </b-col>
      </b-row>
    </section>
    <testResults
      v-if="currentItem && (actualType !== 'history')"
      :opportunities="currentItem.opportunities"
      :approves="currentItem.approves"
      :diagnostics="currentItem.diagnostic"
    />
  </div>
</template>

<script>
import {
  BCol, BRow, BSkeleton, BSkeletonImg,
} from 'bootstrap-vue'

import statics from './Performance/Statics.vue'
import score from './Performance/Score.vue'
import testResults from './Performance/Components/Resume.vue'
import performanceRepository from '@/services/psi'
import History from './Performance/Components/HistoryChart.vue'
import functions from './Performance/Components/performanceFunctions'

export default {
  components: {
    BCol,
    BRow,
    testResults,
    History,
    BSkeleton,
    statics,
    score,
    BSkeletonImg,
  },
  data() {
    return {
      currentItem: null,
      actualType: null,
      website: '',
      date: '',
      performanceResults: null,
      siteKey: null,
      chartUpdate: {
        scores: [0],
        colors: [],
        labels: [],
      },
      dataQuery: null,
      dataCache: {},
    }
  },
  async created() {
    // default site if site id is wrong
    this.siteKey = this.$route.params.id ? this.$route.params.id : 'citymap_com_gt'
    // detect if set key to show specific result
    const mobileResult = this.$route.query.m ? this.$route.query.m : null
    const desktopResult = this.$route.query.d ? this.$route.query.d : null
    let modeShow = this.$route.query.selected ? this.$route.query.selected : 'history'
    modeShow = modeShow.toLowerCase()
    // request data to api
    const t = await performanceRepository.actions.getHistory(this.siteKey)
    this.dataCache.week = t.data
    // Show 404 page if not have results
    if (!t.data) {
      this.$router.push({ path: '/error-404' })
      return
    }
    this.website = t.data.ssiteUrl
    // render results
    this.performanceResults = functions.formatPerformance(t.data)
    if (mobileResult && desktopResult) {
      // Show results if request the key and site id is correctly
      await this.getDataUI(modeShow, desktopResult, mobileResult)
    } else {
      // Default data if not have a key or is wrong
      this.actualType = 'history'
      if (t.data.lastDesktop && t.data.lastMobile) {
        // Obtener ultimos registros
        const response = await performanceRepository.actions.getItems(this.siteKey, t.data.lastDesktop, t.data.lastMobile)
        this.dataQuery = response.data
      }
    }
  },
  methods: {
    async getDataUI(modeShow, desktopResult, mobileResult) {
      const response = await performanceRepository.actions.getItems(this.siteKey, desktopResult, mobileResult)
      this.dataQuery = response.data
      const resultRequested = response.data[modeShow]
      this.actualType = modeShow
      this.date = functions.generateDateString(resultRequested.timestamp)
      this.currentItem = resultRequested.data
      this.renderCurrentItem()
    },
    renderValues(type) {
      this.actualType = type
      this.date = functions.generateDateString(this.dataQuery[type].timestamp)
      this.currentItem = this.dataQuery[type].data
      this.renderCurrentItem()
    },
    renderCurrentItem() {
      if (this.currentItem) {
        const valuePerformance = this.currentItem.scores.performance
        this.chartUpdate = {
          ...this.chartUpdate, scores: [valuePerformance], labels: valuePerformance, colors: functions.getColorChart(valuePerformance),
        }
      }
    },
    async tryUpdate(event) {
      await this.getDataUI(event.selected.toLowerCase(), event.d, event.m)
    },
    async updateChartByRange(value) {
      if (this.dataCache[value]) {
        this.performanceResults = functions.formatPerformance(this.dataCache[value])
      } else {
        const data = await performanceRepository.actions.getHistory(this.siteKey, value)
        this.dataCache[value] = data.data
        this.performanceResults = functions.formatPerformance(data.data)
      }
    },
  },
}
</script>
