<template>
  <div>
    <StaticsCardHorizontal
      v-if="currentItem"
      icon="LoaderIcon"
      :statistic="currentItem.times.speed_index"
      statistic-title="Page Load Time"
      class="shadow-none"
    />
    <StaticsCardHorizontal
      v-if="currentItem"
      icon="CheckCircleIcon"
      :statistic="parseToMb(currentItem.scores.pageSize)"
      statistic-title="Total Page Size"
      class="shadow-none"
    />
    <StaticsCardHorizontal
      v-if="currentItem"
      icon="Minimize2Icon"
      :statistic="currentItem.scores.requests"
      statistic-title="Requests"
      class="mb-0 shadow-none"
    />
  </div>
</template>

<script>
import StaticsCardHorizontal from './Components/Chart/StaticsCardHorizontal.vue'

export default {
  components: {
    StaticsCardHorizontal,
  },
  props: {
    currentItem: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    parseToMb(val) {
      return `${(val / 1000000).toFixed(2)}MB`
    },
  },
}
</script>
