<template>
  <section
    class="section_TestResults"
  >
    <h2>Test Results</h2>
    <b-tabs>
      <b-tab v-if="approves">
        <template #title>
          Approved <span class="counter_approved">{{ approves.length }}</span>
        </template>
        <app-collapse accordion>
          <div class="d-flex p-2">
            <h4 class="w-11/12 ml-3">
              Passed Test
            </h4>
            <h4 class="w-1/12 text-center mr-2">
              Score
            </h4>
          </div>
          <app-collapse-item
            v-for="(item, i) in approves"
            :id="'approve-'+i"
            :key="i"
            :unique="'approve-'+i"
            :title="item.label"
            :grade="item.displayValue"
            :score-display-mode="item.scoreDisplayMode"
            :numeric-value="item.numericValue"
            :better="item.items.items && item.scoreDisplayMode!== 'informative'"
            type="approved"
            :score="item.score"
          >
            <template v-if="item.items && item.items.type === 'table' || item.items.type === 'opportunity'">
              <tableCollapse
                :items="item.items.items"
                :fields="item.items.headings"
              />
            </template>
            <template v-else>
              Nothing here.
            </template>
          </app-collapse-item>
        </app-collapse>
      </b-tab>
      <b-tab
        v-if="diagnostics"
      >
        <template #title>
          Diagnostics <span class="counter_diagnostics">{{ diagnostics.length }}</span>
        </template>
        <app-collapse accordion>
          <div class="d-flex p-2">
            <h4 class="w-100 ml-3">
              Failed test
            </h4>
            <!-- <h4 class="w-1/12 text-center mr-2">
              Grade
            </h4> -->
          </div>
          <app-collapse-item
            v-for="(item, i) in diagnostics"
            :id="'diagnostic-'+i"
            :key="i"
            :unique="'diagnostic-'+i"
            :title="item.label"
            :grade="item.displayValue"
            :score-display-mode="item.scoreDisplayMode"
            :numeric-value="item.numericValue"
            :better="item.items.items ? true : false"
            :score="item.score"
            :value="item.value"
            type="diagnostic"
          >
            <template v-if="item.items && item.items.type === 'table' || item.items.type === 'opportunity'">
              <tableCollapse
                :items="item.items.items"
                :fields="item.items.headings"
              />
            </template>
            <template v-else>
              Nothing here.
            </template>
          </app-collapse-item>
        </app-collapse>
      </b-tab>
      <b-tab
        v-if="opportunities"
        active
      >
        <template #title>
          Opportunities <span class="counter_opportunities">{{ opportunities.length }}</span>
        </template>
        <app-collapse accordion>
          <div class="d-flex p-2">
            <h4 class="w-11/12 ml-3">
              Failed test
            </h4>
            <h4 class="w-1/12 text-center mr-2">
              Grade
            </h4>
          </div>
          <app-collapse-item
            v-for="(item, i) in opportunities"
            :id="'opportunity-'+i"
            :key="i"
            :unique="'opportunity-'+i"
            :title="item.label"
            :grade="item.value"
            :score-display-mode="item.scoreDisplayMode"
            :numeric-value="item.numericValue"
            :better="item.items.items && item.scoreDisplayMode!== 'informative'"
            :score="item.score"
            :value="item.value"
            type="opportunity"
          >
            <template v-if="item.items && item.items.type === 'table' || item.items.type === 'opportunity'">
              <tableCollapse
                :items="item.items.items"
                :fields="item.items.headings"
              />
            </template>
            <template v-else>
              Nothing here.
            </template>
          </app-collapse-item>
        </app-collapse>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import AppCollapse from './Collapse/AppCollapse.vue'
import AppCollapseItem from './Collapse/AppCollapseItem.vue'
import tableCollapse from './Collapse/TableInCollapse.vue'

export default {
  components: {
    BTabs,
    BTab,
    AppCollapse,
    AppCollapseItem,
    tableCollapse,
  },
  props: {
    opportunities: {
      type: Array,
      required: true,
      default: () => [],
    },
    approves: {
      type: Array,
      required: true,
      default: () => [],
    },
    diagnostics: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
</script>
