<template>
  <ol>
    <li v-if="cacheData.week">
      <span class="dot bad" />Average Week: <b v-if="average.week">{{ average.week % 1 === 0 ? average.week : (average.week).toFixed(2) }}{{ typeGraph ? '%' : 's' }}</b></li>
    <li v-if="cacheData.month">
      <span class="dot bad" />Average Month: <b v-if="average.month">{{ average.month % 1 === 0 ? average.month : (average.month).toFixed(2) }}{{ typeGraph ? '%' : 's' }}</b>
    </li>
    <li v-if="cacheData.year">
      <span class="dot bad" />Average Year: <b v-if="average.year">{{ average.year % 1 === 0 ? average.year : (average.year).toFixed(2) }}{{ typeGraph ? '%' : 's' }}</b>
    </li>
  </ol>
</template>

<script>
export default {
  props: {
    cacheData: {
      type: Object,
      required: true,
      default: () => {},
    },
    typeGraph: {
      type: Boolean,
      required: true,
    },
    isDesktop: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    typeString() {
      return this.typeGraph ? 'performance' : 'loadTime'
    },
    typeDevice() {
      return this.isDesktop ? 'desktop' : 'mobile'
    },
    average() {
      const average = {
        week: 0,
        month: 0,
        year: 0,
      }
      if (this.cacheData.week) {
        average.week = this.cacheData.week[this.typeString][`AVG_${this.typeDevice}`]
      }
      if (this.cacheData.month) {
        average.month = this.cacheData.month[this.typeString][`AVG_${this.typeDevice}`]
      }
      if (this.cacheData.year) {
        average.year = this.cacheData.year[this.typeString][`AVG_${this.typeDevice}`]
      }
      return average
    },
  },
}
</script>
